<template>
  <Form
    :model="formItem"
    ref="editUserForm"
    :rules="ruleValidate"
    label-position="right"
    @submit.native.prevent
    :label-width="150"
  >
    <Divider />
    <FormItem label="用户名:">
      <Input
        v-model="formItem.username"
        disabled
        placeholder="请输入用户名"
        autocomplete="username"
      ></Input>
    </FormItem>
    <FormItem label="昵称:" prop="display_name">
      <Input
        v-model="formItem.display_name"
        placeholder="请输入昵称"
        autocomplete="nickname"
      ></Input>
    </FormItem>
    <FormItem prop="campus" label="学校:">
      <!-- 第一个选择器  选择学校  -->
      <Select
        v-model="formItem.campus"
        placeholder="请选择学校"
        @on-change="handleCampusSelected"
      >
        <Icon type="ios-school" :size="16" slot="prefix" />
        <Option
          v-for="item in campusList"
          :value="item.value"
          :key="item.value"
          >{{ item.value }}</Option
        >
      </Select>
    </FormItem>
    <FormItem label="学院:">
      <!-- 第二个选择器  选择学院  -->
      <Select v-model="formItem.faculty" placeholder="请选择学院">
        <Icon type="logo-buffer" :size="16" slot="prefix" />
        <Option
          v-for="item in facultyList"
          :value="item.value"
          :key="item.value"
          >{{ item.value }}</Option
        >
      </Select>
    </FormItem>
    <!-- <FormItem label="学校:" style="width: 350px">
          <Select v-model="formItem.campus" placeholder="请选择学校">
            <Option :value="formItem.campus">{{ formItem.campus }}</Option>
          </Select>
        </FormItem>
        <FormItem label="学院:" style="width: 350px">
          <Select v-model="formItem.faculty" placeholder="请选择学院">
            <Option :value="formItem.faculty" :key="formItem.faculty">{{
              formItem.faculty
            }}</Option>
          </Select>
    </FormItem>-->

    <FormItem
      prop="campus"
      label="类型:"
      v-if="privilege >= 3 && formItem.user_type != 'student'"
    >
      <!-- 第一个选择器  选择类型  -->
      <Select
        v-if="privilege >= 3"
        v-model="formItem.user_type"
        placeholder="用户类型"
        @on-change="handleUserTypeSelected"
      >
        <Icon type="ios-school" :size="16" slot="prefix" />
        <Option
          v-for="item in user_typeList"
          :value="item.value"
          :key="item.value"
          >{{ item.value }}</Option
        >
      </Select>
    </FormItem>

    <FormItem
      label="权限:"
      v-if="privilege >= 3 && formItem.user_type != 'student'"
    >
      <!-- 第二个选择器  选择权限  -->
      <Select
        v-if="privilege >= 3 && formItem.user_type != 'student'"
        v-model="formItem.privilege"
        placeholder="用户权限"
      >
        <Icon type="logo-buffer" :size="16" slot="prefix" />
        <Option
          v-for="item in privilegeList"
          :value="item.value"
          :key="item.value"
          >{{ item.value }}</Option
        >
      </Select>
      <Select
        v-if="privilege >= 3 && formItem.user_type == 'student'"
        v-model="formItem.privilege"
        placeholder="用户权限"
      >
        <Icon type="logo-buffer" :size="16" slot="prefix" />
        <Option>{{ privilegeList[0].value }}</Option>
      </Select>
    </FormItem>

    <FormItem label="邮箱:" prop="email">
      <Input v-model="formItem.email" autocomplete="email"></Input>
    </FormItem>
    <FormItem label="微信:">
      <Input v-model="formItem.wx"></Input>
    </FormItem>
    <FormItem label="电话:">
      <Input v-model="formItem.telephone" autocomplete="wx"></Input>
    </FormItem>
    <FormItem label="学期:">
      <Select v-model="formItem.semester" placeholder="请选择学期">
        <Option value="1">1</Option>
        <Option value="2">2</Option>
      </Select>
    </FormItem>

    <FormItem
      prop="newPassword"
      label="新密码:"
      v-if="resetPassword && privilege < 3"
    >
      <Input
        type="password"
        v-model="formItem.newPassword"
        password
        placeholder="输入新密码"
        autocomplete="newpassword"
      ></Input>
    </FormItem>
    <FormItem
      prop="adm-newPassword"
      label="新密码:"
      v-if="resetPassword && privilege === 3"
    >
      <Input
        type="password"
        v-model="formItem.newPassword"
        password
        placeholder="输入新密码"
        autocomplete="newpassword"
      ></Input>
    </FormItem>
    <FormItem prop="rePassword" label="确认新密码:" v-if="resetPassword">
      <Input
        type="password"
        v-model="formItem.rePassword"
        password
        placeholder="确认新密码"
        autocomplete="newpassword"
      ></Input>
    </FormItem>
    <FormItem>
      <Button
        type="primary"
        style="margin-right: 5%"
        @click="handleUpdate('editUserForm')"
        >更新</Button
      >

      <Button
        type="warning"
        style="margin-right: 5%"
        @click="resetPasswordToggle()"
        >修改密码</Button
      >

      <Button style="margin-right: 5%" type="error" @click="handleDelete()"
        >删除</Button
      >

      <Button type="default" @click="handleReturn()" style="margin-right: 0%">
        <!-- <Icon type="ios-arrow-back" /> -->
        取消
      </Button>
    </FormItem>
  </Form>
</template>
<script>
export default {
  data() {
    const validatePassCheck = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请重新输入密码"));
      } else if (value !== this.formItem.newPassword) {
        callback(new Error("确认密码与新密码不匹配"));
      } else {
        callback();
      }
    };
    // const validatePrice = (rule, value, callback) => {
    //   if (value === "" || isNaN(value)) {
    //     callback(new Error("Please enter price"));
    //   } else {
    //     if (value < 10 || value > 10000) {
    //       callback(new Error("Price should be between 10 to 10000"));
    //     }
    //     callback();
    //   }
    // };
    return {
      resetPassword: false,
      formItem: {
        username: "",
        display_name: "",
        uid: "",
        user_type: "",
        privilege: 0,
        telephone: "",
        campus: "",
        faculty: "",
        email: "",
        wx: "",
        semester: "",
        oriPassword: "",
        newPassword: ""
      },
      user_typeList: [],
      privilegeList: [],
      campusList: [],
      facultyList: [],
      ruleValidate: {
        display_name: [
          {
            required: true,
            message: "The display name cannot be empty",
            trigger: "blur"
          }
        ],
        email: [
          {
            type: "email",
            message: "请输入正确Email格式",
            trigger: "blur"
          }
        ],
        oriPassword: [
          {
            required: true,
            message: "请输入原密码",
            trigger: "blur"
          }
        ],
        newPassword: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          {
            type: "string",
            pattern:
              /^(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){0,})(?!.*\s).{8,20}$/,
            message: "最少8位,至少包含1个大写字母、1个小写字母、1个数字",
            trigger: "blur"
          }
        ],
        rePassword: [
          { required: true, validator: validatePassCheck, trigger: "blur" }
        ]
        // rePassword2: [
        //   { required: true, validator: validatePassCheck, trigger: "blur" }
        // ]
        // price: [
        //   {
        //     required: true,
        //     message: "The price cannot be empty",
        //     trigger: "blur"
        //   },
        //   // { type: 'number', min: 10, max: 10000, message: 'The price should be a number', trigger: 'blur' },
        //   { validator: validatePrice, trigger: "blur" }
        // ],
        // description: [
        //   {
        //     required: true,
        //     message: "The description cannot be empty",
        //     trigger: "blur"
        //   },
        //   {
        //     type: "string",
        //     min: 1,
        //     max: 200,
        //     message: "The description length should less than 200 words",
        //     trigger: "blur"
        //   }
        // ]
      }
    };
  },
  methods: {
    resetPasswordToggle() {
      // if (this.resetPassword) {
      // }
      this.resetPassword = !this.resetPassword;
    },
    handleUpdate() {
      // console.log(this.$refs['newUnitForm']);
      // this.$refs[name].validate(valid => {
      //   if (valid) {
      const username = this.formItem.username;
      const display_name = this.formItem.display_name;
      const uid = this.formItem.uid;
      const user_type = this.formItem.user_type;
      const privilege = this.formItem.privilege;
      const telephone = this.formItem.telephone;
      const campus = this.formItem.campus;
      const faculty = this.formItem.faculty;
      const email = this.formItem.email;
      const wx = this.formItem.wx;
      const semester = this.formItem.semester;
      const newPassword = this.formItem.newPassword;

      const updateInfo = {
        username,
        display_name,
        uid,
        user_type,
        privilege,
        telephone,
        campus,
        faculty,
        email,
        wx,
        semester,
        newPassword
      };
      // handle password
      if (!this.resetPassword) {
        delete updateInfo.newPassword;
      }
      // console.log(updateInfo);
      this.$refs.editUserForm.validate(valid => {
        if (valid) {
          this.axios
            .put(`api/users/${this.formItem.username}`, updateInfo)
            .then(resp => {
              // console.log(resp.data);
              if (resp.data.username) {
                this.$Message.success(`更新成功`);
              }
            })
            .catch(() => {
              this.$Message.error(`更新失败`);
            });
          // back
          this.$router.push("/manageuser");
        }
      });
      //   } else {
      //     this.$Message.error("Failed to update the user!");
      //   }
      // });
    },
    handleUserUpdate() {
      // console.log(this.$refs['newUnitForm']);
      // this.$refs[name].validate(valid => {
      //   if (valid) {
      const display_name = this.formItem.display_name;
      const telephone = this.formItem.telephone;
      const campus = this.formItem.campus;
      const faculty = this.formItem.faculty;
      const email = this.formItem.email;
      const wx = this.formItem.wx;
      const semester = this.formItem.semester;
      const oriPassword = this.formItem.oriPassword;
      const newPassword = this.formItem.newPassword;
      const updateInfo = {
        display_name,
        telephone,
        campus,
        faculty,
        email,
        wx,
        semester,
        oriPassword,
        newPassword
      };
      // handle password
      if (!this.resetPassword) {
        delete updateInfo.newPassword;
        delete updateInfo.oriPassword;
      }
      // console.log(updateInfo);
      this.$refs.editUserForm.validate(valid => {
        if (valid) {
          this.axios
            .put(`api/users/${this.formItem.username}`, updateInfo)
            .then(resp => {
              // console.log(resp.data);
              if (resp.data.username) {
                this.$Message.success(`更新成功`);
              }
            })
            .catch(() => {
              this.$Message.error(`更新失败`);
            });
          // back
          this.$router.push("/usercentre");
        }
      });
      // } else {
      //   this.$Message.error("Failed to update the user!");
      // }
      // });
    },
    handleDelete() {
      this.axios
        .delete(`api/users/${this.formItem.username}`)
        .then(resp => {
          if (resp.status === 204) {
            this.$Message.success(`删除成功`);
            this.$router.push("/manageuser");
          }
        })
        .catch(() => {
          this.$Message.error(`删除失败`);
        });
    },
    handleReturn() {
      this.$router.push("/manageuser");
    },
    handleUserReturn() {
      this.$router.push("/usercentre/enrolledunits");
    },
    handleCampusSelected(selectedCampus) {
      this.axios.get(`api/campus/${selectedCampus}/faculties`).then(resp => {
        // console.log(resp)
        const facaulties = resp.data;
        this.facultyList = facaulties.map(faculty => ({ value: faculty }));
        this.faculty = facaulties[0];
      });
    },
    handleUserTypeSelected() {
      if (this.formItem.user_type === "student") {
        this.formItem.privilege = 0;
      }
      if (this.formItem.user_type === "teacher") {
        this.formItem.privilege = 1;
      }
    }
  },
  computed: {
    privilege: function () {
      return this.$store.getters.getCurrentUser.privilege;
    }
    // username: function() {
    //   return this.$store.getters.getCurrentUser.username
    // }
  },
  mounted: function () {
    const id = this.$route.query.id;
    this.axios.get(`api/users/${id}`).then(resp => {
      // console.log(resp.data);
      const userInfo = resp.data;
      this.formItem.username = userInfo.username;
      this.formItem.display_name = userInfo.display_name;
      this.formItem.uid = userInfo.uid;
      this.formItem.user_type = userInfo.user_type;
      this.formItem.privilege = userInfo.privilege;
      this.formItem.telephone = userInfo.telephone;
      this.formItem.campus = userInfo.campus;
      this.formItem.faculty = userInfo.faculty;
      this.formItem.email = userInfo.email;
      this.formItem.wx = userInfo.wx;
      this.formItem.semester = userInfo.semester;
      return userInfo.username;
    });
    this.axios.get("api/campuses").then(resp => {
      // console.log(resp)
      const campuses = resp.data;
      this.campusList = campuses.map(campus => ({ value: campus }));
      //   设置默认值
      if (campuses) {
        //  设置默认的campus
        // this.campus = campuses[0];
        //   把得到的 string array  转成  object array
        this.axios.get(`api/campus/${campuses[0]}/faculties`).then(resp1 => {
          // console.log(resp)
          const facaulties = resp1.data;
          //   把得到的 string array  转成  object array
          this.facultyList = facaulties.map(faculty => ({ value: faculty }));
          // this.faculty = facaulties[0];
        });
      }
    });
    this.user_typeList = ["student", "teacher", "advisor"].map(user_type => ({
      value: user_type
    }));
    this.privilegeList = [0, 1, 2, 3].map(privilege => ({
      value: privilege
    }));
    // this.axios.get("api/teachers").then(resp => {
    //   const teachersData = resp.data;
    //   this.teacherList = teachersData.map(teacher => ({
    //     value: teacher.display_name,
    //     username: teacher.username
    //   }));
    // });
    this.$Message.config({
      top: 50,
      duration: 3
    });
  }
};
</script>
